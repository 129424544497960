// --------------------------------------------------
// Media queries
// --------------------------------------------------

@mixin desktop() {
  @media screen and (min-width: $desktop) {
    @content;
  }
}

@mixin laptop() {
  @media screen and (min-width: $laptop) {
    @content;
  }
}

@mixin tablet() {
  @media screen and (min-width: $tablets) {
    @content;
  }
}

@mixin phone() {
  @media screen and (min-width: $phone) {
    @content;
  }
}


// --------------------------------------------------
// Clearfix
// --------------------------------------------------

// eg: @include clearfix;
@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }
}