.main-header {
  position: fixed;
  top: auto;
  left: 0;
  z-index: 9999;
  width: 100%;

  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #000;

  @include laptop() {
    padding-top: 13px;
    padding-bottom: 16px;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 100%;

    @include phone() {
      max-width: 100%;
    }
  }

  &__left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1 1 auto;
    max-width: 100px;

    @include phone() {
      max-width: none;
    }

    @include laptop() {
      max-width: 432px;
      width: 100%;
      justify-content: space-between;
    }
  }

  &__right {
    flex: 1 1 auto;
  }

  &__logo {
    max-width: 7px;
    margin-right: 12px;
    margin-left: 6px;

    &-text {
      display: none;
    }

    @include phone() {
      max-width: none;
      margin-left: 13px;
      margin-right: 57px;

      &-img {
        display: none;
      }
      &-img-full {
        width: 275px;
      }

      &-text {
        font-family: 'Exo 2', sans-serif;
        display: block;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: .9;
        letter-spacing: 5.4px;
        color: #fff;
      }
    }

    @include laptop() {
      margin-left: 0;
      margin-right: 10px;
      margin-top: 7px;
      &-text {
        font-size: 25px;
        letter-spacing: 7.7px;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__buttons-link {
    display: block;
    margin-right: 11px;

    &:first-child {
      display: none;
    }
    &:last-child {
      margin-right: 0px;
    }
    img {
      display: block;
      max-width: 120px;
      height: 33px;
    }

    @include phone() {
      img {
        height: 36px;
      }
      &:first-child {
        display: block;
      }
      &:last-child {
        margin-right: 0px;
      }
    }
  }

}