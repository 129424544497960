.select-lang {
  position: relative;
  flex: 1 1 auto;
  max-width: 59px;
  height: 36px;
  text-transform: uppercase;

  &--active {
    .select-lang__btn-icon {
      transform: rotate(180deg);
    }

    .select-lang__list {
      display: block;
    }
  }

  @include laptop() {
    font-family: "Exo 2", sans-serif;
    max-width: 67px;
    padding-top: 0px;
  }

  &__btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 4px;

    line-height: 1;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;

    @include laptop() {
      font-size: 16px;
    }
  }

  &__btn-icon {
    width: 12px;
    height: 6px;
    stroke-width: 2px;
    stroke: #fff;

    transition: transform 0.3s ease;
  }

  &__list {
    display: none;
    position: absolute;
    top: 100%;
    left: -3px;
    z-index: 1;

    padding: 5px 0 5px;
    width: 100%;
    margin-bottom: 0;

    box-shadow: 0 0 5px rgba(#fff, 0.5);
    border-radius: 5px;
    background: #000;
  }

  &__link {
    display: block;
    line-height: 1;
    color: #fff;

    padding: 3px 8px 5px;
    
    &:hover {
      background-color: rgba(#fff, 0.2);
    }
  }

}