.main-footer {
  background-color: #000;
  padding-left: 15px;
  padding-right: 15px;

  @include phone() {
    &__wrap {
      max-width: 1200px;
      padding-right: 7px;
      padding-left: 7px;
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-bottom: 2px;
    border-bottom: 1px solid #383838;
    
    @include phone() {
      padding-top: 4px;
      padding-bottom: 7px;
    }

    @include laptop() {
      padding-top: 10px;
    }
  }

  &__logo {
    width: 220px;
    @include phone() {
      width: 100%;
    }
    &-text {
      font-family: 'Exo 2', sans-serif;
      font-weight: normal;
      font-size: 18px;
      line-height: .7;
      letter-spacing: 5.5px;
      color: #fff;
    }

  }

  &__social {
    flex: 1 1 auto;
    max-width: 81px;
    margin-right: -16px;

    @include phone() {
      margin-right: 0;
    }
  }

  &__middle {
    padding-top: 20px;
    
    @include phone() {
      display: flex;
      justify-content: space-between;
      align-items: stretch;

      padding-top: 25px;
      padding-bottom: 7px;
    }

    @include laptop() {
      padding-top: 36px;
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    padding-bottom: 13px;

    @include phone() {
      flex: 1 1 auto;
      max-width: 263px;
      width: 100%;
      margin-right: 55px;
    }

    @include laptop() {
      max-width: 376px;
    }
  }

  &__description {
    padding-top: 16px;
    border-top: 1px solid #383838;
    color: #767676;

    font-weight: normal;
    font-size: 12px;
    line-height: 1.41;

    p {
      margin-bottom: 12px;
    }

    sup {
      font-size: 13px;
      top: 1px;
      color: #8DC63F;
    }

    a {
      text-decoration: underline;
      color: #fff;

      &:hover {
        text-decoration: none;
      }
    }

    @include phone() {
      flex: 0 1 auto;
      border: none;
      padding-top: 0;

      font-size: 14px;
      line-height: 1.43;
      margin-top: -5px;

      p {
        margin-bottom: 16px;
      }
    }

    @include laptop() {
      max-width: 669px;
    }
  }
  &__disclaimer {
    padding-top: 16px;
    border-top: 1px solid #383838;
    color: #767676;

    font-weight: normal;
    font-size: 12px;
    line-height: 1.41;

    p {
      margin-bottom: 4px;
    }

    @include phone() {
      border: none;
      padding-top: 0;

      font-size: 14px;
      line-height: 1.43;

      p {
        margin-bottom: 16px;
      }
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 13px;
    padding-bottom: 25px;
    
    @include phone() {
      padding-top: 15px;
      border-top: 1px solid #383838;
    }

    @include laptop() {
      padding-top: 37px;
    }

    &-text {
      font-size: 12px;
      line-height: 1.5;
      color: #CFCFCF;

      margin-right: 16px;

      @include phone() {
        margin-right: 0;
        font-size: 14px;
        line-height: 1.43;

        max-width: 240px;
      }

      @include laptop() {
        max-width: none;
        font-size: 15px;
      }
    }

    &-text p {
      margin-bottom: 0;
    }

    &-text a {
      color: #CFCFCF;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }

    &-logo {
      max-width: 111px;
      width: 100%;
      flex: 1 1 auto;

      @include phone() {
        margin-right: 76px;
      }

      @include laptop() {
        margin-right: 0;
      }
    }
  }

}

.social {

  &__list {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin-bottom: 0;
  }

  &__item {
    max-width: 40px;
    height: 40px;
    width: 100%;
    flex: 1 1 auto;
    margin-right: 1px;
    background-color: #000000;

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    &:hover {
      .social__icon {
        fill: #8DC63F;
      }
    }
  }


  &__icon {
    fill: #fff;
    transition: fill 0.3s ease;

    &--inst {
      width: 16px;
      height: 16px;
    }

    &--fb {
      width: 8px;
      height: 16px;
    }
    &--ok {
      width: 10px;
      height: 16px;
    }
    &--vk {
      width: 14px;
      height: 9px;
    }
  }

}