/**
  * bonus-game style
  * © Eliot Purouski - eliotpurouski@gmail.com
  */

@import 'scroll-top-btn';
@import 'bonus-game';
@import 'how-up';
@import 'progress-bar';
@import 'timer';
@import 'bonus-game-faq';
@import 'miss-oportunity';
@import 'exit-loyalty-program-faq';
@import 'section-notice';
@import 'section-share';
