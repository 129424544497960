//Media-queries
$desktop: 1200px;
$laptop: 1110px;
$tablets: 768px;
$phone: 620px;

//Fonts-family
$font-family-main: "Exo 2 Condensed", sans-serif;

//Fonts-setting
$font-weight-main: normal;
$font-size-main: 16px;
$line-height-main: 1.55;

//Colors
$color-main:  #323942;
