.how-get {
    padding-top: 25px;
    padding-bottom: 29px;
  
    @include laptop() {
      padding-bottom: 104px;
    }
  
    .section-title {
      margin-bottom: 30px;
      @include phone() {
        margin-bottom: 50px;
        margin-left: 5px;
      }
      @include laptop() {
        margin-bottom: 50px;
        margin-left: 117px;
      }
    }
    &__btn {
      justify-content: center;
      display: flex;
      margin-top: 25px;
      @include laptop() {
        margin-top: 50px;
      }
    }
  
    &__list {
      position: relative;
  
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-wrap: wrap;
      padding-left: 15px;
      padding-right: 15px;
  
      margin-left: -11px;
  
      @include phone() {
        margin-left: -30px;
      }
    }
  
    &__item {
      flex: 1 1 auto;
      max-width: calc(50% - 11px);
      width: 100%;
      padding: 5px 12px 20px;
      margin-left: 11px;
      margin-bottom: 13px;
  
      border-radius: 18px;
      background-color: #f7f8f8;
  
      @include phone() {
        padding: 10px 5px 20px;
        max-width: calc(25% - 20px);
        margin-left: 20px;

      }
  
      @include laptop() {
        padding: 10px 15px 38px;
        max-width: 205px;
        margin-left: 40px;
        }
    }
    &__number {
        color: #aaadb4;
        font-size: 18px;
    }
  
    &__icon {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      height: 95px;
      margin-bottom: 25px;
      &--1 {
        img {
            max-width: 99px;
        }
      }

      &--2 {
        img {
            max-width: 77px;
        }
      }

      &--3 {
        img {
            max-width: 124px;
        }
      }

      &--4 {
        img {
            max-width: 85px;
        }
      }     
      @include laptop() {
        margin: 0 auto;
        height: 120px;
        img {
          width: 100%;
        }
  
        &--1 {
          max-width: 108px;
        }
  
        &--2 {
          max-width: 85px;
        }
  
        &--3 {
          max-width: 136px;
        }
  
        &--4 {
          max-width: 93px;
        }
      }
    }
  
    &__text {
      width: 100%;
      margin: 0 auto;
  
      font-size: 15px;
      font-weight: 600;
      line-height: 1.22;
      text-align: center;
  
      p {
        margin-bottom: 0;
      }
  
      @include laptop() {
        max-width: none;
  
        font-size: 18px;
  
        br {
          display: none;
        }
      }
    }
  }