/*
 * String delimiter
 * span.str-delimiter
 */

.str-delimiter {

  &_phone {

    @include phone() {
      display: block;
    }
  }

  &_tablet {

    @include tablet() {
      display: block;
    }
  }

  &_laptop {

    @include laptop() {
      display: block;
    }
  }

  &_desktop {

    @include desktop() {
      display: block;
    }
  }
}

.str-delimiter-off {
  display: block;

  &_phone {

    @include phone() {
      display: none;
    }
  }

  &_tablet {

    @include tablet() {
      display: none;
    }
  }

  &_laptop {

    @include laptop() {
      display: none;
    }
  }

  &_desktop {

    @include desktop() {
      display: none;
    }
  }
}
