/**
  * how-up style
  */

.how-up {
  @extend .how-get;

  &__title {
    text-align: center !important;
    margin-left: 0 !important;
  }

  &__list {
    @extend .feature__list;
    justify-content: center;
  }

  &__item {
    flex: 1 1 auto;
    max-width: calc(50% - 11px);
    width: 100%;
    padding: 21px 10px 20px;
    margin-left: 11px;
    margin-bottom: 13px;

    border-radius: 18px;
    background-color: #f7f8f8;

    @include phone() {
      padding: 21px 5px 20px;
      max-width: calc(25% - 30px);
      margin-left: 30px;
    }

    @include laptop() {
      padding: 10px 30px 25px;
    }
  }

  &__icon {
    @extend .feature__icon;
    margin-bottom: 0 !important;
    height: auto !important;

    img {
      height: 116px;
      width: auto !important;
    }
  }

  &__text {
    @extend .feature__text;

    p {
      margin-bottom: 0;
    }
  }
}
