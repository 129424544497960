/**
  * miss-oportunity style
  */

.miss-oportunity {
  background-color: #F7F8F8;
  padding-top: 20px;
  padding-bottom: 35px;
  padding-left: 15px;
  padding-right: 15px;

  @include phone() {
    padding-top: 40px;
    padding-bottom: 55px;
  }

  @include tablet() {
    padding-left: 0;
    padding-right: 0;
    padding-top: 60px;
    padding-bottom: 75px;
  }

  @include desktop() {
    padding-top: 80px;
    padding-bottom: 95px;
  }

  &__title {
    color: $color-main;
    font-weight: 600;
    font-size: 28px;
    line-height: 1.28;
    margin-bottom: 25px;
    text-align: center;

    @include phone() {
      line-height: 1.2;
      margin-bottom: 45px;
    }

    @include tablet() {
      line-height: 1.2;
      margin-bottom: 65px;
    }

    @include laptop() {
      margin-bottom: 85px;
      font-size: 40px;
      line-height: 1.2;
    }
  }

  &__visual {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include phone() {
      flex-direction: row;
      justify-content: space-around;
    }

    img {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;

      @include phone() {
        max-width: 476px;
      }
    }
  }
}
