.unsubscribe {
  position: relative;
  padding-top:58px;
  padding-bottom: 16px;
  text-align: center;

  @include phone() {
    padding-top: 110px;
    padding-bottom: 70px;

  }

  &__wrapper {
    margin-top: 40px;
    width: 90%;    
    @include phone() {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-direction: row-reverse;
      max-width: 684px;
    }
  }

  @include laptop() {
    padding-top: 58px;
    margin-bottom: 22px;
  }

    &__card {
    position: relative;
    max-width: 90vw;
    width: 100%;
    margin: 0 auto 23px;
    transition-property: transform;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    transition-delay: 0.1s;

    &:hover{
      transform: scale(0.9);
    }

    @include phone() {
      max-width: 319px;
      margin: 0;
      padding-top: 10px;
    }

    @include laptop() {
      max-width: 600px;
      padding-top: 0;
     
    }
  }

  &__card-img {
    position: relative;
    img {
      width: 100%;
    }
    
    @include laptop() {
      img {
        width: 100%;
        margin-left: 30px;
        margin-top: 70px;
      }
    }

  }
  &__description {
    width: 100%;
  }
  &__description-wrap {
    max-width: 90vw;
    width: 100%;
    margin: 0 auto;
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    @include phone() {
      max-width: 270px;
      margin: 40px 0 0 0;
      text-align: left;
    }

    @include laptop() {
      max-width: 570px;
      padding-top: 20px;
      justify-content: start;
      align-items: start;
      justify-items: start;  
    }
  }
  h1 {
 /*   font-size: 40px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;*/
    margin-bottom: 22px;
    @include laptop() {
        text-align: left; 
    }
  }
  h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
    margin-bottom: 25px;
  }
  h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 0px;
  }
  a.link {
      color: #40C53E;
  }

  &__text {
    margin-bottom: 40px;
    font-size: 26px;
    line-height: 1.54;
    text-align: center;
    br {
      display: none;
    }
    
    @include phone() {
      max-width: 250px;
      margin-bottom: 29px;
      line-height: 1.54;
    }

    @include laptop() {
      max-width: none;
      text-align: left;
      br {
        display: block;
      }
    }
  }

  &__form {
    text-align: center;
    background-color: #F7F8F8;
    border-radius: 20px;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    @include phone() {
      margin-top: 100px;
      width: 684px;
      padding:  20px 0px 0px 0px;
    }
    @include laptop() {
      margin-top: 0;
      width: 100%;
      padding:  20px 0px 0px 0px;
    }
    &--wrapper {
      padding-top: 20px;
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;      
    }
    &--bottom {
      height: 36px;
      background: #D1D3D6;
      border-radius: 0px 0px 20px 20px;
      width: 100%;
      display: none;
      cursor: pointer;
      padding-top: 6px;
    }
  }

  &__promo-code {
    background: #FFFFFF;
    border: 1px solid #D1D3D4;
    border-radius: 70px;    
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    letter-spacing: 0.1em;
    padding: 0 40px;
    margin-bottom: 25px;
  }

  &__disclaimer {
    a {
      font-size: 12px;
      line-height: 14px;
      color: #323942;
      font-weight: 400;;
      padding-left: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    svg {
      margin-right: 4px;
    }
    &--link {
      display: block;
      margin-bottom: 14px;
    }
    &--text {
      display: block;
      text-align: left;
      font-size: 12px;
      line-height: 14.57px;
      visibility: visible;
      transition: visibility 0s linear 300ms, opacity 300ms;
      opacity: 1;
      p {
        padding-bottom: 10px;
      }
      a {
        color: #323942;
        text-decoration: underline;
      }
    }
  }
  &__btn {
    &--mobile {
      @include phone() {
        display: none;
      }
    }  
    &--desktop {
      display: none;
      @include phone() {
        display: block;
      }
    }  
    width: 100%;
    margin-bottom: 25px;
  }

  &__icons {
    margin-bottom: 20px;
    width: 100%;
    a {
      display: flex;
      justify-content: center;
    }
    ul {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      margin-bottom: 0;
    }
    li {
      height: 40px;
      width: 40px;
      flex: 1 1 auto;
      margin-right: 1px;
      &:last-child {
        margin-right: 0;
      }
    }
    img {
      width: 36px;
      height: 36px; 

      @include phone() {
        width: 40px;
        height: 40px; 
  
      }
    }
  }
  .btn-main {
    margin: 0;
  }
}
