/**
  * bonus-game-faq style
  */

.bonus-game-faq {
  padding-top: 25px;
  padding-bottom: 35px;
  padding-left: 15px;
  padding-right: 15px;

  @include phone() {
    padding-top: 20px;
    padding-bottom: 35px;
  }

  @include tablet() {
    padding-left: 0;
    padding-right: 0;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @include desktop() {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  &__title {
    margin-bottom: 30px;
    text-align: center !important;

    @include phone() {
      margin-bottom: 50px;
    }

    @include laptop() {
      margin-bottom: 50px;
    }
  }

  &__faq {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    @include tablet() {
      flex-direction: row;
    }

    & > * {
      flex: 1 1 100%;
    }
  }

  &__column {
    width: 100%;

    @include tablet() {
      margin-left: 20px;
      margin-right: 20px;
    }
  }

  &__block {
    padding-top: 4px;
    padding-bottom: 4px;
  }

  &__question, &__answer {
    padding: 15px;
    transition: all .3s ease;
  }

  &__question {
    background: #DADBDE;
    border-radius: 10px;
    position: relative;
    cursor: pointer;

    &::after {
      content: '';
      display: block;
      background-image: url(/static/img/svg/down-arrow.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      width: 28px;
      height: 28px;
      right: 20px;
      top: 18px;
    }

    h3 {
      margin-bottom: 0;
    }

    @at-root .show & {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__answer {
    border-radius: 0 0 10px 10px;
    background-color: #F7F8F8;
    transform: scaleY(0);
    transform-origin: top;
    height: 0;
    padding-top: 0;
    padding-bottom: 0;

    @at-root .show & {
      transform: scaleY(1);
      padding: 15px;
      height: 100%;
    }

    a {
      transition: all .3s ease;

      &,
      &:hover,
      &:visited,
      &:active {
        color: inherit;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
