@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('../fonts/Exo2/exo-2-v8-latin_cyrillic-300.woff2') format('woff2'),
  url('../fonts/Exo2/exo-2-v8-latin_cyrillic-300.woff') format('woff');
}

@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/Exo2/exo-2-v8-latin_cyrillic-regular.woff2') format('woff2'),
  url('../fonts/Exo2/exo-2-v8-latin_cyrillic-regular.woff') format('woff');
}

@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('../fonts/Exo2/exo-2-v8-latin_cyrillic-500.woff2') format('woff2'),
  url('../fonts/Exo2/exo-2-v8-latin_cyrillic-500.woff') format('woff');
}

@font-face {
  font-family: 'Exo 2';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('../fonts/Exo2/exo-2-v8-latin_cyrillic-600.woff2') format('woff2'),
  url('../fonts/Exo2/exo-2-v8-latin_cyrillic-600.woff') format('woff');
}

@font-face {
  font-family: 'Exo 2 Condensed';
  src: local('Exo 2 Medium Condensed'), local('Exo2-MediumCondensed'),
  url('../fonts/Exo2/Exo2-MediumCondensed.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Exo 2 Condensed';
  src: local('Exo 2 Semi Bold Condensed'), local('Exo2-SemiBoldCondensed'),
  url('../fonts/Exo2/Exo2-SemiBoldCondensed.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


@font-face {
  font-family: 'Exo 2 Expanded';
  src: local('Exo 2 Semi Bold Expanded'), local('Exo2-SemiBoldExpanded'),
  url('../fonts/Exo2/Exo2-SemiBoldExpanded.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Exo 2 Expanded';
  src: local('Exo 2 Medium Expanded'), local('Exo2-MediumExpanded'),
  url('../fonts/Exo2/Exo2-MediumExpanded.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Exo 2 Expanded';
  src: local('Exo 2 Regular Expanded'), local('Exo2-RegularExpanded'),
  url('../fonts/Exo2/Exo2-RegularExpanded.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Exo 2 Expanded';
  src: local('Exo 2 Extra Light Expanded'), local('Exo2-ExtraLightExpanded'),
  url('../fonts/Exo2/Exo2-ExtraLightExpanded.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Exo 2 Condensed';
  src: local('Exo 2 Bold Condensed'), local('Exo2-BoldCondensed'),
  url('../fonts/Exo2/Exo2-BoldCondensed.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Exo 2 Condensed';
  src: local('Exo 2 Light Condensed'), local('Exo2-LightCondensed'),
  url('../fonts/Exo2/Exo2-LightCondensed.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Exo 2 Condensed';
  src: local('Exo 2 Regular Condensed'), local('Exo2-RegularCondensed'),
  url('../fonts/Exo2/Exo2-RegularCondensed.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Exo 2 Expanded';
  src: local('Exo 2 Bold Expanded'), local('Exo2-BoldExpanded'),
  url('../fonts/Exo2/Exo2-BoldExpanded.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

