.feature {
  padding-top: 25px;
  padding-bottom: 29px;

  @include laptop() {
    padding-bottom: 104px;
  }

  &__list {
    position: relative;

    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    flex-wrap: wrap;
    padding-left: 15px;
    padding-right: 15px;

    margin-left: -11px;

    @include phone() {
      margin-left: -30px;
    }
  }

  &__item {
    flex: 1 1 auto;
    max-width: calc(50% - 11px);
    width: 100%;
    padding: 21px 10px 20px;
    margin-left: 11px;
    margin-bottom: 13px;

    border-radius: 18px;
    background-color: #f7f8f8;

    @include phone() {
      padding: 21px 5px 20px;
      max-width: calc(25% - 30px);
      margin-left: 30px;
    }

    @include laptop() {
      padding: 36px 5px 49px;
    }
  }

  &__icon {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 36px;
    margin-bottom: 15px;

    @include laptop() {
      margin: 0 auto 15px;
      height: 64px;

      img {
        width: 100%;
      }

      &--iban {
        max-width: 112px;
      }

      &--sepa {
        max-width: 128px;
      }

      &--worldwide {
        max-width: 76px;
      }

      &--delivery {
        max-width: 70px;
      }
    }
  }

  &__text {
    max-width: 122px;
    width: 100%;
    margin: 0 auto;

    font-size: 18px;
    font-weight: 600;
    line-height: 1.22;
    text-align: center;

    p {
      margin-bottom: 0;
    }

    @include laptop() {
      max-width: none;

      font-size: 20px;

      br {
        display: none;
      }
    }
  }
}