.main-nav {

  &__col {
    flex: 1 1 auto;
    max-width: 168px;

    &:first-child {
      margin-right: 20px;
    }

    @include laptop() {
      max-width: 205px;
    }
  }

  &__item {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }

    @include phone() {
      margin-bottom: 15px;
    }
  }

  &__link {
    position: relative;
    display: block;
    font-family: 'Exo 2', sans-serif;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    padding-left: 15px;

    color: #9D9D9D;
    transition: color 0.3s ease;
    
    &:hover {
      color: #8DC63F;
    }

    &::before {
      content: '';

      position: absolute;
      top: 52%;
      left: 0;
      transform: translateY(-50%);

      width: 6px;
      height: 9px;
      
      background-image: url("../img/svg/arrow-right.svg");
      background-size: cover;
      background-repeat: no-repeat;
    }

    @include laptop() {
      font-size: 16px;
    }
  }

}