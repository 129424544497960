/**
  * progress-bar style
  */

$animDuration: 10s;

#progress-bar {
  max-width: 446px;
  max-height: 430px;
}

.progress-bar {
  /*background-image: url(/static/img/progress-bar/clock-face.svg);*/
  background-repeat: no-repeat;
  height: 403px;
  width: 446px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 30px;
  position: relative;

  @media screen and (min-width: 320px) and (max-width: 455.08px) {
    height: 280px;
    width: 310px;
    padding-bottom: 26px;
  }

  &__bg {
    position: absolute;
    top: -23px;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__arc {

    &-part {
      display: none;

      &_100 {

        @at-root .step-state-1 & {
          display: block;
        }
      }

      &_200 {

        @at-root .step-state-2 & {
          display: block;
        }
      }

      &_300 {

        @at-root .step-state-3 & {
          display: block;
        }
      }

      &_400 {

        @at-root .step-state-4 & {
          display: block;
        }
      }

      &_500 {

        @at-root .step-state-5 & {
          display: block;
        }
      }

      &_600 {

        @at-root .step-state-6 & {
          display: block;
        }
      }

      &_700 {

        @at-root .step-state-7 & {
          display: block;
        }
      }

      &_800 {

        @at-root .step-state-8 & {
          display: block;
        }
      }

      &_900 {

        @at-root .step-state-9 & {
          display: block;
        }
      }

      &_1000 {

        @at-root .step-state-10 & {
          display: block;
        }
      }
    }
  }

  &__dot {

    &_active {
      fill: #FF9900;
    }
  }

  &_win {
    background-image: url(/static/img/progress-bar/clock-face_finish.svg);
    justify-content: center;
    padding-bottom: 0;

    @media screen and (min-width: 320px) and (max-width: 455.08px) {
      padding-top: 20px;
    }
  }

  &__spend-days {
    margin-left: auto;
    margin-right: auto;
    width: 66px;
    margin-bottom: 10px;
  }

  &__days-left {
    background-color: white;
    font-size: 40px;
    line-height: normal;

    @media screen and (min-width: 320px) and (max-width: 455.08px) {
      font-size: 30px;
    }

    &-descr {
      background-color: #f69802;

      @media screen and (min-width: 320px) and (max-width: 455.08px) {
        font-size: 14px;
      }
    }
  }

  &__header {
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 5px;

    @media screen and (min-width: 320px) and (max-width: 455.08px) {
      margin-bottom: 2px;
      font-size: 16px;
    }

    &_win {
      font-size: 44px;

      @media screen and (min-width: 320px) and (max-width: 455.08px) {
        margin-bottom: 2px;
        font-size: 27px;
      }
    }

    &_loss {
      position: absolute;
      bottom: -5px;
    }
  }

  &__time {

    & + * {
      margin-top: 20px;

      @media screen and (min-width: 320px) and (max-width: 455.08px) {
        margin-top: 10px;
      }
    }
  }

  &__timer {}

  &__cashback {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &_win {
      margin-top: 0;
    }

    &_loss {
      margin-top: 40px;
    }
  }

  &__money {
    border-radius: 40px;
    padding: 0px 20px 5px;
    font-size: 50px;
    line-height: 1;
    font-weight: 700;
    position: relative;

    @media screen and (min-width: 320px) and (max-width: 455.08px) {
      padding: 0 15px 2px;
      font-size: 32px;
      min-height: 37px;
      min-width: 97px;
    }

    &_loss {
      width: 132px;

      @media screen and (min-width: 320px) and (max-width: 455.08px) {
        width: auto;
      }
    }

    &_win {
      font-size: 70px;
      padding: 0 30px 10px;
      border-radius: 40px 40px 0 40px;
      background-color: #9fc71f !important;

      &::after {
        display: none !important;
      }

      @media screen and (min-width: 320px) and (max-width: 455.08px) {
        font-size: 50px;
        padding: 0 15px 4px;
      }
    }

    &,
    &::after {
      background-color: #f69802;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 40px;
      width: 40px;
      top: calc(50% - 20px);
      right: -3px;
      transform: rotate(45deg);
      border-radius: 40px 0 40px 100%;
      z-index: -1;

      @media screen and (min-width: 320px) and (max-width: 455.08px) {
        height: 36px;
        width: 36px;
        top: calc(50% - 18px);
        right: 0;
      }
    }
  }

  &__pointer {
    height: 66px;
    width: 66px;
    border-radius: 100%;
    position: absolute;

    @media screen and (min-width: 320px) and (max-width: 455.08px) {
      height: 46px;
      width: 46px;
    }

    &_win {
      height: 85px;
      width: 85px;
    }

    &_active {
      bottom: 22px;
      left: 72px;
      transform: rotate(138deg);

      @media screen and (min-width: 320px) and (max-width: 455.08px) {
        bottom: 21px;
        left: 50px;
      }

      &::before,
      &::after {
        content: '';
        display: block;
      }

      &::before {
        position: absolute;
        height: 50px;
        width: 50px;
        top: 8px;
        left: 8px;
        border-radius: 100%;
        background-color: #fff;
        background-image: url(/static/img/progress-bar/logo.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-size: auto 90%;
        background-position: center;
        transform: rotate(-138deg);

        @media screen and (min-width: 320px) and (max-width: 455.08px) {
          top: 4px;
          left: 4px;
          width: 38px;
          height: 38px;
        }
      }

      &,
      &::after {
        background-color: #9fc71f;
      }

      &::after {
        position: absolute;
        top: calc(50% - 29px);
        right: -1px;
        width: 58px;
        height: 58px;
        transform: rotate(45deg);
        border-radius: 50px 0 50px 100%;
        z-index: -1;

        @media screen and (min-width: 320px) and (max-width: 455.08px) {
          top: calc(50% - 23px);
          right: 0px;
          width: 46px;
          height: 46px;
        }
      }

      &_win {
        left: auto;
        right: 75px;

        @media screen and (min-width: 320px) and (max-width: 455.08px) {
          left: auto;
          right: 50px;
        }

        &::after {
          width: 77px;
          height: 77px;
          top: calc(50% - 44px);
          right: 5px;
          transform: rotate(-54deg);
          z-index: 2;
          border-radius: 60px 0 60px 100%;
        }
      }

      &_loss {
        bottom: auto;
        top: 24px;
        left: calc(50% - 33px);

        @media screen and (min-width: 320px) and (max-width: 455.08px) {
          top: 15px;
          left: calc(50% - 23px);
        }

        &::after {
          top: 7px;
          right: 7px;
          transform: rotate(175deg);

          @media screen and (min-width: 320px) and (max-width: 455.08px) {
            top: calc(50% - 23px);
            right: 0px;
            width: 46px;
            height: 46px;
          }
        }
      }

      @at-root .animate & {
        left: calc(50% - 33px);
        bottom: calc(50% - 33px - 30px);
        z-index: 1;
        transform: rotate(0deg) translate(-118px, 116px) rotate(300deg);
        animation: Progress-bar__active-pointer-orbita $animDuration linear;

        &::before {
          transform: rotate(60deg);
          animation: Progress-bar__active-pointer-orbita-inner $animDuration linear;
        }

        &::after {
          transform: rotate(243deg);
          top: calc(50% - 30px);
          right: 9px;

          @media screen and (min-width: 320px) and (max-width: 455.08px) {
            top: calc(50% - 23px);
            right: 1px;
          }
        }

        @media screen and (min-width: 320px) and (max-width: 455.08px) {
          bottom: calc(50% - 23px - 19px);
          left: calc(50% - 21px);
          transform: rotate(0deg) translate(-84px, 77px) rotate(300deg);
          animation-name: Progress-bar__active-pointer-orbita_xs;
        }
      }

      @at-root .step-state-0 & {

      }

      @at-root .step-state-1 & {
        bottom: 80px;
        left: 39px;
        z-index: 1;
        transform: rotate(339deg);

        &::before {
          transform: rotate(21deg);
        }

        &::after {
          transform: rotate(243deg);
          top: calc(50% - 30px);
          right: 9px;

          @media screen and (min-width: 320px) and (max-width: 455.08px) {
            top: calc(50% - 23px);
            right: 1px;
          }
        }

        @media screen and (min-width: 320px) and (max-width: 455.08px) {
          bottom: 62px;
          left: 24px;
          transform: rotate(339deg);
        }
      }

      @at-root .step-state-2 & {
        bottom: 180px;
        left: 26px;
        z-index: 1;
        transform: rotate(339deg);

        &::before {
          transform: rotate(21deg);
        }

        &::after {
          transform: rotate(243deg);
          top: calc(50% - 30px);
          right: 9px;

          @media screen and (min-width: 320px) and (max-width: 455.08px) {
            top: calc(50% - 23px);
            right: 1px;
          }
        }

        @media screen and (min-width: 320px) and (max-width: 455.08px) {
          bottom: 132px;
          left: 20px;
          transform: rotate(339deg);
        }
      }

      @at-root .step-state-3 & {
        bottom: 245px;
        left: 61px;
        z-index: 1;
        transform: rotate(12deg);

        &::before {
          transform: rotate(349deg);
        }

        &::after {
          transform: rotate(246deg);
          top: calc(50% - 30px);
          right: 9px;

          @media screen and (min-width: 320px) and (max-width: 455.08px) {
            top: calc(50% - 23px);
            right: 1px;
          }
        }

        @media screen and (min-width: 320px) and (max-width: 455.08px) {
          bottom: 175px;
          left: 42px;
          transform: rotate(12deg);
        }
      }

      @at-root .step-state-4 & {
        bottom: 290px;
        left: 111px;
        z-index: 1;
        transform: rotate(44deg);

        &::before {
          transform: rotate(316deg);
        }

        &::after {
          transform: rotate(246deg);
          top: calc(50% - 30px);
          right: 9px;

          @media screen and (min-width: 320px) and (max-width: 455.08px) {
            top: calc(50% - 23px);
            right: 1px;
          }
        }

        @media screen and (min-width: 320px) and (max-width: 455.08px) {
          bottom: 209px;
          left: 78px;
          transform: rotate(42deg);
        }
      }

      @at-root .step-state-5 & {
        bottom: 311px;
        left: calc(50% - 33px);
        z-index: 1;
        transform: rotate(67deg);

        &::before {
          transform: rotate(294deg);
        }

        &::after {
          transform: rotate(246deg);
          top: calc(50% - 30px);
          right: 9px;

          @media screen and (min-width: 320px) and (max-width: 455.08px) {
            top: calc(50% - 23px);
            right: 1px;
          }
        }

        @media screen and (min-width: 320px) and (max-width: 455.08px) {
          bottom: 223px;
          left: calc(50% - 23px);
          transform: rotate(66deg);
        }
      }

      @at-root .step-state-6 & {
        bottom: 294px;
        left: 263px;
        z-index: 1;
        transform: rotate(96deg);

        &::before {
          transform: rotate(265deg);
        }

        &::after {
          transform: rotate(246deg);
          top: calc(50% - 30px);
          right: 9px;

          @media screen and (min-width: 320px) and (max-width: 455.08px) {
            top: calc(50% - 23px);
            right: 1px;
          }
        }

        @media screen and (min-width: 320px) and (max-width: 455.08px) {
          bottom: 211px;
          left: 181px;
          transform: rotate(97deg);
        }
      }

      @at-root .step-state-7 & {
        bottom: 253px;
        left: 317px;
        z-index: 1;
        transform: rotate(133deg);

        &::before {
          transform: rotate(228deg);
        }

        &::after {
          transform: rotate(246deg);
          top: calc(50% - 30px);
          right: 9px;

          @media screen and (min-width: 320px) and (max-width: 455.08px) {
            top: calc(50% - 23px);
            right: 1px;
          }
        }

        @media screen and (min-width: 320px) and (max-width: 455.08px) {
          bottom: 182px;
          left: 219px;
          transform: rotate(133deg);
        }
      }

      @at-root .step-state-8 & {
        bottom: 182px;
        left: 352px;
        z-index: 1;
        transform: rotate(159deg);

        &::before {
          transform: rotate(202deg);
        }

        &::after {
          transform: rotate(246deg);
          top: calc(50% - 30px);
          right: 9px;

          @media screen and (min-width: 320px) and (max-width: 455.08px) {
            top: calc(50% - 23px);
            right: 1px;
          }
        }

        @media screen and (min-width: 320px) and (max-width: 455.08px) {
          bottom: 133px;
          left: 243px;
          transform: rotate(159deg);
        }
      }

      @at-root .step-state-9 & {
        bottom: 98px;
        left: 350px;
        z-index: 1;
        transform: rotate(179deg);

        &::before {
          transform: rotate(182deg);
        }

        &::after {
          transform: rotate(246deg);
          top: calc(50% - 30px);
          right: 9px;

          @media screen and (min-width: 320px) and (max-width: 455.08px) {
            top: calc(50% - 23px);
            right: 1px;
          }
        }

        @media screen and (min-width: 320px) and (max-width: 455.08px) {
          bottom: 75px;
          left: 243px;
          transform: rotate(179deg);
        }
      }

      @at-root .step-state-10 & {
        bottom: 24px;
        left: 305px;
        z-index: 1;
        transform: rotate(200deg);

        &::before {
          transform: rotate(161deg);

          @media screen and (min-width: 320px) and (max-width: 455.08px) {
            opacity: 0;
          }
        }

        &::after {
          transform: rotate(246deg);
          top: calc(50% - 30px);
          right: 9px;

          @media screen and (min-width: 320px) and (max-width: 455.08px) {
            top: calc(50% - 23px);
            right: 1px;
          }
        }

        @media screen and (min-width: 320px) and (max-width: 455.08px) {
          bottom: 22px;
          left: 214px;
          transform: rotate(200deg);
        }
      }
    }

    &_finish {
      background-color: #D1D3D6;
      bottom: 24px;
      right: 75px;

      @media screen and (min-width: 320px) and (max-width: 455.08px) {
        right: 50px;
        bottom: 21px;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        height: 60px;
        width: 60px;
        top: 3px;
        left: 3px;
        border-radius: 100%;
        background-image: url(/static/img/progress-bar/pointer-finish.svg);
        background-repeat: no-repeat;
        background-size: contain;
        transition: background-image .3s ease;
        z-index: 1;

        @media screen and (min-width: 320px) and (max-width: 455.08px) {
          top: 2px;
          left: 3px;
          width: 41px;
          height: 41px;
        }
      }

      @at-root .animate & {

        &::before {
          animation: Progress-bar__finish-pointer-orbita-bg $animDuration linear;
        }
      }

      @at-root .step-state-10 & {

        &::before {
          background-image: url(/static/img/progress-bar/pointer-finish_active.svg);
        }
      }

      &_active {
        background-color: #9fc71f;

        &::before {
          background-image: url(/static/img/progress-bar/pointer-finish_active.svg);
        }
      }

      &_win {

        &::before {
          height: 80px;
          width: 80px;
        }
      }
    }
  }
}

@keyframes Progress-bar__active-pointer-orbita {
  0% {
    transform: rotate(0deg) translate(-118px, 116px) rotate(300deg);
  }
  20% {
    transform: rotate(0deg) translate(-118px, 116px) rotate(300deg);
  }

  50% {
    transform: rotate(269deg) translate(-116px, 113px) rotate(290deg);
  }

  70% {
    transform: rotate(269deg) translate(-116px, 113px) rotate(290deg);
  }

  100% {
    transform: rotate(0deg) translate(-118px, 116px) rotate(300deg);
  }
}

@keyframes Progress-bar__active-pointer-orbita_xs {
  0% {
    transform: rotate(0deg) translate(-84px, 77px) rotate(300deg);
  }
  20% {
    transform: rotate(0deg) translate(-84px, 77px) rotate(300deg);
  }

  50% {
    transform: rotate(269deg) translate(-78px, 79px) rotate(293deg);
  }

  70% {
    transform: rotate(269deg) translate(-78px, 79px) rotate(293deg);
  }

  100% {
    transform: rotate(0deg) translate(-84px, 77px) rotate(300deg);
  }
}

@keyframes Progress-bar__active-pointer-orbita-inner {
  0% {
    transform: rotate(0deg) rotate(60deg);
  }
  20% {
    transform: rotate(0deg) rotate(60deg);
  }

  50% {
    transform: rotate(0deg) rotate(-195deg);
  }

  70% {
    transform: rotate(0deg) rotate(-195deg);
  }

  100% {
    transform: rotate(0deg) rotate(60deg);
  }
}

@keyframes Progress-bar__finish-pointer-orbita-bg {
  0% {
    background-image: url(/static/img/progress-bar/pointer-finish.svg);
  }
  49.5% {
    background-image: url(/static/img/progress-bar/pointer-finish.svg);
  }

  50% {
    background-image: url(/static/img/progress-bar/pointer-finish_active.svg);
  }
  70% {
    background-image: url(/static/img/progress-bar/pointer-finish_active.svg);
  }

  70.5% {
    background-image: url(/static/img/progress-bar/pointer-finish.svg);
  }
  100% {
    background-image: url(/static/img/progress-bar/pointer-finish.svg);
  }
}
