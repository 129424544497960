/**
  * bonus-game section style
  */

.bonus-game {
  align-self: center;
  text-align: center;
  padding-bottom: 35px;

  @include phone() {
    padding-bottom: 35px;
  }

  @include tablet() {
    padding-bottom: 40px;
  }

  @include desktop() {
    padding-bottom: 70px;
  }

  &_1 {

    @include desktop(){
      padding-top: 140px;
      margin-bottom: 0;
      padding-bottom: 30px;
    }
  }

  &__wrapper {
    align-items: center !important;

    @media screen and (min-width: $phone) and (max-width: $laptop - 0.02) {
      display: block;
    }

    & > * {
      flex: 1 1 100%;
    }

    &_5 {
      align-items: flex-start !important;
    }
  }

  &__progress-bar {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__description {
    margin-top: 50px;
    padding-left: 15px;
    padding-right: 15px;

    @include tablet() {
      padding-left: 0;
      padding-right: 0;
    }

    @include laptop() {
      margin-top: 0;
    }
  }

  &__description-wrap,
  &__title,
  &__text {

    @media screen and (max-width: $laptop - 0.02) {
      max-width: 100% !important;
      text-align: center !important;
      margin-left: 0 !important;
    }
  }

  &__description-wrap {}

  &__title {}

  &__text {}

  &__share {
    margin-top: 30px;

    &-title {
      margin-bottom: 25px;

      @include laptop() {
        text-align: left;
      }
    }
  }

  &__btn {

    @media screen and (min-width: $phone) and (max-width: $laptop - 0.02) {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &__referral-text {
    @extend .referral__text;

    @media screen and (min-width: $phone) and (max-width: $laptop - 0.02) {
      max-width: 100% !important;
    }

    @include laptop() {
      text-align: left;
    }
  }

  &__referral-icons {

    li {

      @include laptop() {
        flex-grow: 0;
        height: 60px;
        width: 60px;
      }

      & + li {

        @include laptop() {
          margin-left: 15px;
        }
      }
    }

    img {

      @include laptop() {
        height: 60px;
        width: 60px;
      }
    }
  }
}
