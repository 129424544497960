.bonus {
  position: relative;
  padding-top: 89px;
  padding-bottom: 16px;

  ul {
    list-style-type: disc;
    list-style-position: outside;
    margin-bottom: 20px;
    text-align: left;
    font-size: 22px;
    margin-left: 20px;
  }
  li {
    line-height: 1.5;
  }    


  @include phone() {
    padding-top: 110px;
    padding-bottom: 70px;
    ul {
      font-size: 16px;
    }
    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-direction: row-reverse;
      max-width: 684px;
    }
  }

  @include laptop() {
    padding-top: 150px;
    margin-bottom: 20px;
  }

  &__title {
    h2 {
      @include phone() {
        text-align: center;
      }
    }
    .section-subtitle {
      margin-bottom: 0;
    }
    @include phone() {
      br {
        display: none;
      }
    }
  }

  &__card {
    position: relative;
    max-width: 90vw;
    width: 100%;
    margin: 0 auto 23px;
    transition-property: transform;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    transition-delay: 0.1s;

    &:hover{
      transform: scale(0.9);
    }

    @include phone() {
      max-width: 319px;
      margin: 0;
      padding-top: 10px;
    }

    @include laptop() {
      max-width: 600px;
      padding-top: 0;
     
    }
  }

  &__card-img {
    position: relative;
    img {
      width: 100%;
    }
    
    @include laptop() {
      img {
        width: 90%;
        margin-left: 30px;
      }
    }

  }

  &__description-wrap {
    max-width: 90vw;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    @include phone() {
      max-width: 270px;
      margin: 0;
      text-align: left;
    }

    @include laptop() {
      max-width: 570px;
      padding-top: 20px;
    }
  }
  &__text {
    margin-bottom: 10px;
    font-size: 22px;

    br {
      display: none;
    }
    
    @include phone() {
      max-width: 250px;
      margin-bottom: 29px;
      line-height: 1.5;
    }

    @include laptop() {
      max-width: none;

      font-size: 26px;
      line-height: 1.54;
      margin-bottom: 10px;

      br {
        display: block;
      }
    }
  }

  &__preview-app {
    position: relative;
    padding-top: 58px;
    padding-bottom: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 25px;
    img {
      width: 100%;
    }

    @include phone() {
      display: none;
    }
  }
  &__btn {
    &--mobile {
      margin: 30px 0;
      @include phone() {
        display: none;
      }
    }  
    &--desktop {
      display: none;
      @include phone() {
        display: block;
      }
    }  
  }

}