.body_404 {
  background-color: black;
}
.button {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  color: #ff8f00;
  font-family: "Exo 2 Condensed";
  font-size: 18px;
  font-weight: 400;
  line-height: 1.1;
  letter-spacing: 0.63px;

  padding: 11px 35px 13px;

  border: 2px solid #ff8f00;
  border-radius: 28px;
  background-color: transparent;

  text-decoration: none;
  cursor: pointer
}

.button:hover,
.button:focus {
  background-color: transparent;
  border: 2px solid #ff6900;
  color: #ff6900;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.button--error-page {
  width: 98px;
  height: 26px;
  padding: 2px 10px 3px;
  font-size: 14px;
  line-height: 19px;
  border-width: 1px;
  border-radius: 13px;
}

.button--error-page:hover,
.button--error-page:focus {
  border-width: 1px !important;
}

.input::placeholder {
  color: #fff;
  opacity: 1;
}

.input.input--sign-in::placeholder, .input.input--sign-up::placeholder {
  color: #fff;
}

.input.input--tel::placeholder {
  color: #000;
  opacity: 1;
}

.title {
  font-size: 0;
  margin: 0;
  margin-top: 30px;
}

.title svg {
  fill: #fff;
  max-width: 100%;
  height: 18px;
}

.error-page {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 100vh;
  padding: 23px 0px 0px 0px;
  margin: 0px 0px 0px 13.152vw;
  background-repeat: no-repeat;
  background-position: -65px 0px;
  background-image: url('../img/back-err-640-2.jpg');
  background-size: 110vw;
}

.error-page__intro {
  margin: 22px 0 40px;
  padding: 0;
  font-size: 7.5vw;
  font-weight: 700;
  line-height: 8.125vw;
  color: #404041;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  letter-spacing: 0.015em;
}

.error-page__intro-first-line {
  display: block;
}

.error-page__intro-last-line {
  display: block;
  color: #8dc63f;
}

.error-page__error {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 73.75vw;
  height: 42.1875vw;
  margin-bottom: 37px;
  padding: 10px 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.error-page__number {
  font-size: 11.5625vw;
  font-weight: 700;
  line-height: 11.5625vw;
}

.error-page__text {
  font-size: 6.25vw;
  font-weight: 700;
  line-height: 7.1875vw;
}

.error-page .title {
  margin-top: 0;
}

.error-page .title svg {
  width: 199px;
  height: 15px;
}

@media (max-height: 320px) {
  .error-page .title svg {
    width: 199px;
    height: 15px;
  }
}

@media (min-width: 640px) {
  .button--error-page {
    width: 136px;
    height: 36px;
    padding: 4px 10px;
    font-size: 20px;
    line-height: 26px;
    border-radius: 18px;
  }
  .error-page .title svg {
    width: 260px;
    height: 19px;
  }
}

@media (min-width: 768px) {
  .button {
    font-size: 21.312px;
    font-size: 1.9375rem;
    padding: 9px 50px 13px;
  }
  .button--error-page {
    width: 200px;
    height: 53px;
    padding: 8px 22px;
    font-size: 30px;
    line-height: 30px;
    border-radius: 100px;
  }
  .title {
    margin-top: 0;
  }
  .title svg {
    max-width: none;
    height: 22px;
  }
}

@media (min-width: 992px) {
  html {
    font-size: 11px;
  }
}

@media (min-width: 1024px) {
  .button--error-page {
    width: 190px;
    height: 52px;
    padding: 8px 24px 10px;
    font-size: 27px;
    line-height: 27px;
    border-width: 3px;
    border-radius: 44px;
  }
  .button--error-page:hover,
  .button--error-page:focus {
    border-width: 3px !important;
  }
  .error-page {
    margin: 0px 0px 0px 40px;
    background-image: url('../img/back-err-1080-2.jpg');
    background-size: 70vw;
    background-position: 0px 0px;
  }
  .error-page__intro-first-line {
    display: inline;
  }
}

@media (min-width: 1260px) {
  .error-page {
    margin: 0px 0px 0px 40px;
    background-image: url('../img/back-err-1080-2.jpg');
    background-size: 46.875vw;
    background-position: 0px 0px;
  }
}

@media (min-width: 1440px) {
  .error-page {
    margin: 0px 0px 0px 15vw;
    background-image: url('../img/back-err-1080-2.jpg');
    background-size: 46.875vw;
    background-position: 0px 0px;
  }
}

@media (min-width: 320px) and (min-height: 450px) {
  .error-page__intro {
    margin: 22px 0 50px;
  }
}

@media (min-width: 360px) and (min-height: 450px) {
  .error-page__intro {
    margin: 22px 0 50px;
  }
}

@media (min-width: 375px) and (min-height: 450px) {
  .error-page__intro {
    margin: 22px 0 55px;
  }
}

@media (min-width: 400px) and (min-height: 450px) {
  .error-page__intro {
    margin: 22px 0 70px;
  }
}

@media (min-width: 550px) and (max-height: 450px) {
  .error-page__intro-first-line {
    display: inline;
  }
}

@media (min-width: 560px) and (min-height: 450px) {
  .error-page__intro {
    margin: 22px 0 115px;
  }
}

@media (min-width: 640px) and (max-height: 639px) {
  .error-page__intro-first-line {
    display: inline;
  }
}

@media (min-width: 640px) and (min-height: 640px) {
  .error-page__error {
    margin-bottom: 72px;
  }
}

@media (min-width: 768px) and (max-height: 450px) {
  .error-page {
    margin: 0px 0px 0px 40px;
    background-image: url('../img/back-err-1080-2.jpg');
    background-size: 100vw;
    background-position: 0px 130px;
  }
}

@media (min-width: 768px) and (min-height: 450px) {
  .error-page__intro {
    margin: 22px 0 175px;
  }
  .error-page__error {
    padding: 10px 40px;
  }
}

@media (min-width: 1024px) and (min-height: 450px) {
  .error-page__intro {
    margin: 22px 0 100px;
    font-size: 4.29688vw;
    line-height: 4.58984vw;
    letter-spacing: 0.015em;
  }
  .error-page__error {
    width: 600px;
    height: 320px;
    padding: 16px 0px 0px 33px;
    margin-bottom: 72px;
  }
  .error-page__number {
    font-size: 7.12891vw;
    line-height: 7.12891vw;
  }
  .error-page__text {
    font-size: 3.90625vw;
    line-height: 3.90625vw;
  }
}

@media (min-width: 1200px) and (min-height: 768px) {
  html {
    font-size: 13px;
  }
}

@media (min-width: 1260px) and (min-height: 450px) {
  .error-page__intro {
    margin: 22px 0 calc(13.20312vw - 120px);
    font-size: 3.4375vw;
    line-height: 3.67188vw;
    letter-spacing: 0.015em;
  }
  .error-page__error {
    width: 36.71875vw;
    height: 20.3125vw;
    padding: 16px 0px 0px 33px;
    margin-bottom: 72px;
  }
  .error-page__number {
    font-size: 5.70313vw;
    line-height: 5.70313vw;
  }
  .error-page__text {
    font-size: 3.125vw;
    line-height: 3.125vw;
  }
  .error-page .title svg {
    width: 380px;
    height: 28px;
  }
}

@media (min-width: 1400px) and (min-height: 1024px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1921px) and (min-height: 1080px) {
  html {
    font-size: 18px;
  }
}

@media (max-height: 450px) {
  .error-page__intro {
    font-size: 23px;
    line-height: 26px;
    letter-spacing: 0.015em;
    margin: 0px 0 10px;
  }
  .error-page__error {
    width: 260px;
    height: 150px;
    margin-bottom: 30px;
  }
  .error-page__number {
    font-size: 37px;
    line-height: 37px;
  }
  .error-page__text {
    font-size: 20px;
    line-height: 22px;
  }
}

@media (max-height: 450px) {
  .error-page {
    margin: 0px 0px 0px 40px;
    background-image: url('../img/back-err-1080-2.jpg');
    background-size: 300px;
    background-position: 0px 0px;
  }
}