/**
  * section-share style
  */

.section-share {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 35px;
  padding-left: 15px;
  padding-right: 15px;

  @include phone() {
    padding-top: 20px;
    padding-bottom: 35px;
  }

  @include tablet() {
    padding-left: 0;
    padding-right: 0;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @include desktop() {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  &__share {
    max-width: 645px;
    margin-left: auto;
    margin-right: auto;
  }

  &__title {
    color: $color-main;
    font-weight: 600;
    font-size: 28px;
    line-height: 1.28;
    text-align: center;

    @include phone() {
      line-height: 1.2;
    }

    @include laptop() {
      font-size: 40px;
    }
  }

  &__referral-icons {
    padding-top: 15px;
    padding-bottom: 15px;

    @include phone() {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    @include tablet() {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    @include desktop() {
      padding-top: 70px;
      padding-bottom: 70px;
    }

    ul {
      display: flex;
      flex-direction: row;
      justify-content: center;

      @include tablet() {
        justify-content: space-between;
      }

      li {
        width: 75px;
        height: auto;
        margin: 5px;

        @include tablet() {
          margin: 0;
          height: 75px;
          width: 75px;
        }

        a {
          width: 100%;
          height: 100%;
          display: block;

          img {
            height: auto;
            width: 100%;
          }
        }
      }
    }
  }

  &__description {
    color: $color-main;
    font-size: 18px;
    line-height: 1.55;

    @include phone() {
      font-size: 20px;
      line-height: 1.8;
    }

    @include laptop() {
      font-size: 26px;
      line-height: 1.67;
    }

    p {
      @include laptop() {
        margin-bottom: 10px;
      }
    }
  }
}
