/**
  * section-notice style
  */

.section-notice {
  background-color: #F7F8F8;
  padding-top: 20px;
  padding-bottom: 35px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;

  @include phone() {
    padding-top: 20px;
    padding-bottom: 35px;
  }

  @include tablet() {
    padding-left: 0;
    padding-right: 0;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @include desktop() {
    padding-top: 70px;
    padding-bottom: 70px;
  }

  &__visual {

    img {
      max-width: 140px;
      margin: 0 auto 10px;

      @include tablet() {
        max-width: 190px;
        margin-bottom: 15px;
      }

      @include laptop() {
        max-width: 230px;
        margin-bottom: 20px;
      }
    }
  }

  &__title {
    color: $color-main;
    font-weight: 600;
    font-size: 28px;
    line-height: 1.28;
    margin-bottom: 10px;
    text-align: center;

    @include phone() {
      line-height: 1.2;
      margin-bottom: 15px;
    }

    @include tablet() {
      line-height: 1.2;
      margin-bottom: 20px;
    }

    @include laptop() {
      margin-bottom: 40px;
      font-size: 40px;
      line-height: 1.2;
    }
  }

  &__description {
    color: $color-main;
    font-size: 18px;
    line-height: 1.55;
    max-width: 930px;
    margin-left: auto;
    margin-right: auto;

    @include phone() {
      font-size: 20px;
      line-height: 1.8;
    }

    @include laptop() {
      font-size: 26px;
      line-height: 1.67;
    }

    p {
      @include laptop() {
        margin-bottom: 10px;
      }
    }
  }
  
  &__contacts {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    justify-content: space-around;
    margin-top: 10px;

    @include phone() {
      margin-top: 20px;
    }

    @include laptop() {
      flex-direction: row;
      margin-top: 40px;
    }
  }

  &__btn {
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 15px;

    @include phone() {
      margin-top: 20px;
    }

    @include tablet() {
      margin-top: 25px;
    }

    @include laptop() {
      margin-top: 45px;
    }
  }
}
