.bcc-worldwide {
  margin-top: 65px;

  @include tablet() {
    margin-top: 0;
  }

  &__wrapper {

    @include phone() {
      margin-bottom: 100px;
    }

    @include laptop() {
      margin-bottom: 180px;
    }

    @include desktop() {
      margin-bottom: 100px;
    }
  }

  .description__wrapper {
    position: relative;
  }

  &__text {
    max-width: 290px;
    width: 100%;
    margin: 0 auto 36px;
    text-align: center;

    p {
      margin-bottom: 0;
    }

    @include phone() {
      max-width: 337px;
      margin: 0 0 36px;
    }

    @include laptop() {
      max-width: 500px;
      margin-right: 71px;
    }
  }

  &__animation {
    overflow: hidden;
    margin-top: -40px;
    margin-bottom: -30px;
    margin-left: -96vw;
    position: relative;
    width: 200%;
    z-index: -1;

    @include phone() {
      position: absolute;
      bottom: 0;
      width: 100%;
      margin-top: -50px;
      margin-bottom: -50px;
      margin-left: 0;
    }

    @include tablet() {
      bottom: 0;
      top: auto;
    }

    @include laptop() {
      left: 0;
      bottom: auto;
      top: 0px;
    }

    svg,
    img {
      height: 100%;
      width: 100%;
      max-width: initial;
    }
  }
}
