.btn-main {
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 240px;
  width: 100%;
  height: 55px;
  margin: 0 auto;
  padding: 5px 5px 7px;

  font-weight: 500;
  font-size: 24px;
  line-height: .5;
  text-align: center;

  background-color: #a0c71f;
  border-radius: 40px;
  color: #fff;
  
  transition: background-color 0.3s ease, transform 0.2s ease;
  
  &:hover {
    background-color: #40c53e;
  }

  &:active {
    transform: scale(0.97);
  }

  @include phone() {
    margin: 0;
    max-width: 218px;
  }
}