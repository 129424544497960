.service-fee {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 49px;

  @include phone() {
    margin-bottom: 62px;
  }

  @include laptop() {
    margin-bottom: 128px;
  }

  &__wrapper {
    @include phone() {
      max-width: 646px;
    }

    @include laptop() {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }

  &__card {
    display: none;

    @include laptop() {
      position: relative;
      display: block;
      max-width: 472px;
      width: 100%;
      flex: 1 1 auto;
    }
  }

  &__main {
    padding: 20px 18px 29px;
    border-radius: 18px;
    background-color: #f7f8f8;
    color: #000;

    @include phone() {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      min-height: 204px;
      padding-left: 20px;
      padding-right: 40px;
    }
    
    @include laptop() {
      flex: 1 1 auto;
      min-height: 230px;
      padding: 40px 50px 40px 20px;
      border-radius: 0 18px 18px 0;
      margin-bottom: 45px;
      /*align-items: start;*/
    }

    & > * {
      flex: 1 1 100%;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 1.28;
    text-align: center;
    color: #000;
    margin-bottom: 12px;
    text-align: center;

    sup {
      font-size: 24px;
      top: -3px;
      margin-left: 2px;
      color: #8DC63F;
    }

    @include phone() {
      font-size: 28px;
      margin-bottom: 0;
      /*align-self: start;*/
      /*text-align: left;*/
    }
    @include laptop() {
      /*margin-top: 15px;*/
    }
  }

  &__per-month {
    /*margin-bottom: 15px;*/
    padding-top: 10px;
    display: flex;

    @include tablet() {
      padding-top: 0px;
    }

    @include laptop() {
      padding: 0 0px 0px 0px;
      height: 87px;
      /*margin-bottom: 15px;*/
    }
  }

  &__per-month-number {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    text-align: center;

    min-height: 87px;
    font-family: "Exo 2", sans-serif;
    display: block;
    font-weight: 600;
    font-size: 100px;
    line-height: .6;    
    margin-right: 5px;
  }

  &__per-month-icon {
    width: 28px;
    height: 30px;
    fill: #8DC63F;
  }

  &__per-month-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.21;
    text-align: left;
    display: flex;
    flex-direction: column;
    color: #000000;
    font-family: "Exo 2", sans-serif;
  }
  &__column2 {
    /*min-width: 130px;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include phone() {
      margin-right: 20px;
      /*align-self: flex-start;*/
    }
  }
  &__column3 {
    /*min-width: 218px;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 30px;
    @include phone() {
      margin-top: 0px;      
    }
    &--title {
      font-weight: 600;
      font-size: 24px;
      line-height: 1.28;
      text-align: center;
      color: #000;   
      height: 87px;
    }
  }
}
