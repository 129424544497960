.page-intro {
  position: relative;
  padding-top: 89px;
  padding-bottom: 16px;

  @include phone() {
    padding-top: 110px;
    padding-bottom: 70px;

    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-direction: row-reverse;
      max-width: 684px;
    }
  }

  @include laptop() {
    padding-top: 191px;
    margin-bottom: 71px;
  }

  &_index {

    @include laptop() {
      margin-bottom: 31px;
    }
  }

  &__card {
    position: relative;
    max-width: 300px;
    width: 100%;
    margin: 0 auto 23px;
    transition-property: transform;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    transition-delay: 0.1s;

    &:hover{
      transform: scale(0.9);
    }

    @include phone() {
      max-width: 319px;
      margin: 0;
      padding-top: 10px;
    }

    @include laptop() {
      max-width: 600px;
      padding-top: 0;
     
    }
  }

  &__card-img {
    position: relative;
    img {
      width: 100%;
    }
    
    @include laptop() {
      img {
        width: 90%;
        margin-left: 30px;
      }
    }
    &--popcort {
    	img {
    	  @media screen and (max-width: 400px) {
    	  	max-width: 70vw;
    	  	margin: auto;
		  }
    	}
    }

  }

  &__description-wrap {
    max-width: 90vw;
    width: 100%;
    margin: 0 auto;

    @include phone() {
      max-width: 270px;
      margin: 0;
      text-align: left;
    }

    @include laptop() {
      max-width: 570px;
      padding-top: 20px;
    }
  }

  &__text {
    margin-bottom: 23px;

    br {
      display: none;
    }
    
    @include phone() {
      max-width: 250px;
      margin-bottom: 29px;

      line-height: 1.5;
    }

    @include laptop() {
      max-width: none;

      font-size: 26px;
      line-height: 1.54;
      margin-bottom: 23px;

      br {
        display: block;
      }
    }
  }

  &__preview-app {
    position: relative;
    padding-top: 58px;
    padding-bottom: 40px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 25px;
    img {
      width: 100%;
    }

    @include phone() {
      display: none;
    }
  }
  &__btn {
    max-width: 218px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    @include phone() {
      margin: 0;
    }
  }
  &__promo-code {
    background: #FFFFFF;
    border: 1px solid #D1D3D4;
    border-radius: 40px;    
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    letter-spacing: 0.1em;
    padding: 0 22px;
    margin: 15px auto 15px auto;
    width: 218px;
    cursor: copy;
    &--icon {
      float: right;
      width: 20px;
      height: 20px;
    }
    &--text {
      width: 100%;
      text-align: center;
      padding-left: 20px;    
    }
  }  
  &__promo-code-text {
    font-size: 18px;
    color: #8E8E8E;
    font-weight: 200;
    font-family: 'Exo 2 Condensed';
    text-align: center;
  }
}
