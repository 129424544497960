/**
  * timer style
  */

.timer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-left: 12px;
    font-size: 14px;
    margin-bottom: 5px;

    @media screen and (min-width: 320px) and (max-width: 455.08px) {
      margin-bottom: 2px;
      font-size: 12px;
      margin-left: 0;
    }

    & > * {
      padding: 0 13px;
    }

    &-days {}

    &-hours {}

    &-minutes {}
  }

  &__counter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 40px;
    padding: 0px 20px 5px;
    font-size: 50px;
    line-height: 1;
    min-height: 55px;
    min-width: 205px;

    @media screen and (min-width: 320px) and (max-width: 455.08px) {
      padding: 0 15px 2px;
      font-size: 32px;
      min-height: 37px;
      min-width: 134px;
    }

    & > * {
      & + * {
        &::before {
          content: ":";
          display: inline-block;
        }
      }
    }

    &-days {}

    &-hours {}

    &-minutes {}
  }
}
