/**
  * scroll-top-btn style
  */

$lastPointer: 620px;

.scroll-top-btn {

  &_page {

    &_bonus-game-1,
    &_closed-account,
    &_exit-loyalty-program,
    &_loss-campaign {

      @media screen and (max-width: 380px - 0.02) {
        bottom: 430px;
      }

      @media screen and (min-width: 380px) and (max-width: 440px - 0.02) {
        bottom: 380px;
      }

      @media screen and (min-width: 440px) and (max-width: $lastPointer - 0.02) {
        bottom: 330px;
      }
    }

    &_bonus-game-1 {

      @media screen and (max-width: 380px - 0.02) {
        bottom: 390px;
      }

      @media screen and (min-width: 380px) and (max-width: 440px - 0.02) {
        bottom: 340px;
      }

      @media screen and (min-width: 440px) and (max-width: $lastPointer - 0.02) {
        bottom: 310px;
      }
    }

    &_bonus-game-5,
    &_unsubscribe-mailing,
    &_unsubscription-not-possible,
    &_obsolete-share {

      @media screen and (max-width: 380px - 0.02) {
        bottom: 250px;
      }

      @media screen and (min-width: 380px) and (max-width: $lastPointer - 0.02) {
        bottom: 220px;
      }
    }
  }
}
