.referral {
  position: relative;
  padding-top:58px;
  padding-bottom: 16px;
  text-align: center;

  @include phone() {
    padding-top: 110px;
    padding-bottom: 20px;

    &__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-direction: row-reverse;
      max-width: 684px;
    }
  }

  @include laptop() {
    padding-top: 58px;
    margin-bottom: 22px;
  }

    &__card {
    position: relative;
    max-width: 90vw;
    width: 100%;
    margin: 0 auto 23px;
    transition-property: transform;
    transition-duration: 0.2s;
    transition-timing-function: linear;
    transition-delay: 0.1s;

    &:hover{
      transform: scale(0.9);
    }

    @include phone() {
      max-width: 319px;
      margin: 0;
      padding-top: 10px;
    }

    @include laptop() {
      max-width: 600px;
      padding-top: 0;
     
    }
  }

  &__card-img {
    position: relative;
    img {
      width: 100%;
    }
    
    @include laptop() {
      img {
        width: 100%;
        margin-left: 30px;
        margin-top: 70px;
      }
    }

  }
  &__description {
    width: 100%;
    display: flex;
    align-items: center;
  }
  &__description-wrap {
    max-width: 90vw;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-items: center;
    @include phone() {
      max-width: 270px;
      margin: 20px 0 0 0;
      text-align: left;
    }

    @include laptop() {
      max-width: 570px;
      padding-top: 20px;
    }
  }
  h1 {
  /*  font-size: 40px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;*/
    margin-bottom: 22px;
  }
  h2 {
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    text-align: center;
    margin-bottom: 25px;
  }
  h3 {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    text-align: center;
    margin-bottom: 20px;
    margin-top: 0px;
  }


  &__text {
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 1.54;
    text-align: center;
    br {
      display: none;
    }
    
    @include phone() {
      max-width: 250px;
      margin-bottom: 29px;
      line-height: 1.5;
    }

    @include laptop() {
      max-width: none;

      br {
        display: block;
      }
    }

    &_deactiv {
      font-size: 24px;
      line-height: 1.67;
      padding-left: 20px;
      padding-right: 20px;
      font-weight: 600;
      margin-bottom: 0.7em;

      @include phone() {
        margin-bottom: 0.7em;
      }

      @include laptop() {
        margin-bottom: 0.7em;
      }
    }
  }

  &__form {
    text-align: center;
    background-color: #F7F8F8;
    border-radius: 20px;
    width: 90%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    @include phone() {
      margin-top: 100px;
      width: 684px;
      padding:  20px 0px 0px 0px;
    }
    @include laptop() {
      margin-top: 0;
      width: 100%;
      padding:  20px 0px 0px 0px;
    }
    &--wrapper {
      padding-top: 20px;
      width: 90%;
      display: flex;
      flex-direction: column;
      align-items: center;      
    }
    &--bottom {
      height: 36px;
      background: #D1D3D6;
      border-radius: 0px 0px 20px 20px;
      width: 100%;
      display: none;
      cursor: pointer;
      padding-top: 6px;
    }

    &_deactiv {
      padding-top: 0 !important;

      @include phone() {
        width: 570px;
        z-index: 99;
      }
    }

    &-top {
      width: 100%;
      border-radius: 20px 20px 0px 0px;
      position: relative;

      &::after {
        content: '';
        display: block;
        background: rgba(209, 211, 214, 0.7);
        border-radius: 20px 20px 0 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &-container {
        max-width: 90%;
        margin-left: auto;
        margin-right: auto;
        padding-top: 20px;

        &_deactiv {

          @include phone() {
            max-width: 60%;
          }
        }
      }
    }

    &-bottom {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 10px;
    }
  }

  &__promo-code {
    background: #FFFFFF;
    border: 1px solid #D1D3D4;
    border-radius: 70px;    
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    letter-spacing: 0.1em;
    padding: 0 40px;
    margin-bottom: 25px;

    &_deactiv {
      font-size: 40px;
      padding-top: 10px;
      letter-spacing: -1px;
      width: 85%;
      margin-left: auto;
      margin-right: auto;
      background-color: #D1D3D4;
    }
  }

  &__disclaimer {
    a {
      font-size: 12px;
      line-height: 14px;
      color: #323942;
      font-weight: 400;;
      padding-left: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    svg {
      margin-right: 4px;
    }

    &--link {
      display: block;
      margin-bottom: 14px;
    }
    &--text {
      display: block;
      text-align: left;
      font-size: 12px;
      line-height: 14.57px;
      visibility: visible;
      transition: visibility 0s linear 300ms, opacity 300ms;
      opacity: 1;
      p {
        padding-bottom: 10px;
      }
      a {
        color: #323942;
        text-decoration: underline;
      }

      &_deactiv {
        font-size: 16px;
        line-height: normal;

        ul {
          padding-bottom: 10px;

          li {
            font-size: 14px;
            line-height: normal;
          }
        }
      }
    }

    &-btn.btn-main {
      font-size: 18px;
      line-height: normal;
      color: #fff;
      text-decoration: none;
      margin: 20px auto 20px !important;
    }

    &_deactiv {
      padding: 0 20px;
    }

    &-bottom {
      width: 100%;
      border-radius: 0 0 20px 20px;
      background-color: #A0C71F;

      a {
        margin: 0;
        font-size: 18px;
        color: #fff;
        text-decoration: underline;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        padding: 10px;

        svg {
          margin-right: 3px;

          path {
            fill: #fff;
          }
        }
      }
    }
  }
  &__btn {
    &--mobile {
      @include phone() {
        display: none;
      }
    }  
    &--desktop {
      display: none;
      @include phone() {
        display: block;
      }
    }  
    width: 100%;
    margin-bottom: 25px;
  }

  &__icons {
    margin-bottom: 20px;
    width: 100%;
    a {
      display: flex;
      justify-content: center;
    }
    ul {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      margin-bottom: 0;
    }
    li {
      height: 40px;
      width: 40px;
      flex: 1 1 auto;
      margin-right: 1px;
      &:last-child {
        margin-right: 0;
      }
    }
    img {
      width: 36px;
      height: 36px; 

      @include phone() {
        width: 40px;
        height: 40px; 
  
      }
    }
  }
  .btn-main {
    margin: auto;
  }
}
.hidden {
  display: none;
  visibility: hidden;
  opacity: 0;
}

.hidden--mobile {
  display: none;
  @include phone() {
    display: block;
  }

}

.hidden--desktop {
  display: block;
  @include phone() {
    display: none;
  }
}

.thanks {
  @include phone() {
    margin-top: 150px;
  }
}

.btn-left {
  @include phone() {
    align-self: flex-start; 
    margin: 0 !important;
  }
}
