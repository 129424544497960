.faq {
  position: relative;
  padding-top:30px;
  padding-bottom: 16px;
  text-align: center;

  @include phone() {
    padding-top: 0px;
    padding-bottom: 20px;
    text-align: left;
  }
  &__wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 90%;
    @include phone() {
        width: 100%;
        align-items: flex-start;
    }
  }    

  a {
      color: #A0C71F;
  }

  h1 {
    margin-bottom: 25px;
  }
  ul {
    list-style-type: disc;
    list-style-position: inside;
    margin-bottom: 20px;
  }
  li {
    margin-bottom: 10px;
  }
  &__element {
    padding: 0px;
    margin: 0 0 25px 0;        
    text-align: left;
    &--question {
        display: flex;
        align-items: center;
        justify-items: flex-start;
        cursor: pointer;
        h3 {
            font-size: 18px;
            font-weight: 600;
            line-height: 1.2;
            margin: 0;
            display: flex;
            color: #000000;
            @include phone() {
                font-size: 24px;
            }
          }
        button {
            display: none;
        }

        &--icon-plus {
            width: 20px;
            height: 20px;
            min-width: 20px;
            display: flex;
            margin-right: 12px;
            margin-top: 4px;
            background-image: url(/static/img/bx-plus-circle.svg);
            background-size: contain;
            background-repeat: no-repeat;
            align-self: flex-start;
            @include phone() {
                width: 34px;
                height: 34px;
            }
        }
        &--icon-minus {
            width: 20px;
            height: 20px;
            min-width: 20px;
            display: flex;
            margin-right: 12px;
            margin-top: 4px;
            background-image: url(/static/img/bx-minus-circle.svg);
            background-size: contain;
            background-repeat: no-repeat;
            align-self: flex-start;
            @include phone() {
                width: 34px;
                height: 34px;
            }
        }
        img {
            width: 34px;
            height: 34px;
            display: flex;
            margin-right: 12px;
        }
            
    }
    &--answer {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.2;
        margin: 10px 0 20px 0;
        color: #000000;
        background-color: #ebeef0;
        transition: transform 0.5s ease;    
        transform: scaleY(1);
        transform-origin: top;    
        display: block;
        p {
            margin-bottom: 1em;
        }
        @include phone() {
            margin: 20px 0;
            font-size: 24px;
            font-weight: 400;
            line-height: 30px;        
        }
    }
    &--images {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include phone() {
            flex-direction: row;
            img {
                margin-right: 40px;
            }
        }
    }
  }
}
.hideText {
        transform: scaleY(0);
        transform-origin: top;   
        transition: transform 0.2s ease;    
        height: 0;
        margin: 0 !important;
}
.img_small {
    width: 100px;
    cursor: zoom-in;
}
.img_large {
    width: 270px;
    max-width: 90vw;

}
.img-mb-top {
    margin-top: 20px;
}