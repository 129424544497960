.cookie-agree {
  display: none;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;

  width: 100%;
  background-color: #aaadb4;

  &__wrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding-top: 40px;
    padding-bottom: 21px;

    @include phone() {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      padding-top: 45px;
      padding-bottom: 45px;
    }
  }

  &__close {
    position: absolute;
    top: 14px;
    right: 20px;
    z-index: 10;

    transition: transform 0.3s ease;

    .icon-close {
      width: 18px;
      height: 18px;

      fill: $color-main;

      transition: fill 0.3s ease;
    }

    &:hover {
      .icon-close {
        fill: #fff;
      }
    }

    &:active {
      transform: scale(.92);
    }

    @include phone() {
      top: 14px;
      right: 3%;
    }

  }

  &__text {
    font-size: 14px;
    line-height: 17px;
    font-weight: normal;

    color: #000;
    text-align: center;

    margin-bottom: 21px;

    a {
      text-decoration: underline;
      color: inherit;

      &:hover {
        text-decoration: none;
        color: #ffffff;
      }
    }

    @include phone() {
      font-size: 19px;
      line-height: 24px;
      text-align: left;

      margin-bottom: 0;
    }

    @include laptop() {
      font-size: 24px;
      line-height: 29px;
      text-align: left;

      br {
        display: none;
      }
    }
  }
}

.cookie-buttons {
  max-width: 140px;
  width: 100%;

  @include phone() {
    flex: 1 1 auto;
    max-width: 220px;
  }
}

.cookie-accept {
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 140px;
  width: 100%;
  height: 32px;
  padding-bottom: 3px;

  font-size: 14px;
  font-weight: 500;
  line-height: 1;

  border: 1px solid #000;
  border-radius: 22.5px;
  color: #000;
  cursor: pointer;
  user-select: none;

  transition: color 0.3s ease, background-color 0.3s ease;

  &:hover {
    border-color: #fff;
    color: #fff;
  }

  &:active {
    border-color: #ccc;
    color: #ccc;
  }

  @include phone() {
    font-size: 24px;

    max-width: 220px;
    height: 47px;
  }
}